import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

const routes = [
  {
    path: '*',
    redirect: { name: 'app-dashboard' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Auth/Login.vue')
  },
  {
    path: '/password/:token',
    name: 'create-password',
    component: () => import('@/pages/Auth/CreatePassword.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/pages/Auth/ForgotPassword.vue')
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () => import('@/pages/Auth/ResetPassword.vue')
  },
  {
    path: '/validate-account/:token',
    name: 'validate-account',
    component: () => import('@/pages/Auth/ValidateAccount.vue')
  },
  {
    path: '/app',
    component: () => import('@/views/Layout.vue'),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: '/app/me',
        name: 'app-profile',
        alias: 'profile',
        component: () => import('@/pages/Profile.vue')
      },
      {
        path: '/app/dashboard',
        name: 'app-dashboard',
        alias: '/dashboard',
        component: () => import('@/pages/Dashboard.vue')
      },
      {
        path: '/app/domains',
        name: 'app-domains',
        alias: '/domains',
        component: () => import('@/pages/Domains/List.vue')
      },
      {
        path: '/app/domain/:uuid',
        name: 'app-domain',
        alias: '/domain',
        component: () => import('@/pages/Domains/One.vue')
      },
      {
        path: '/app/questions',
        name: 'app-questions',
        alias: '/questions',
        component: () => import('@/pages/Questions/List.vue')
      },
      {
        path: '/app/surveys',
        name: 'app-surveys',
        alias: '/surveys',
        component: () => import('@/pages/Surveys/List.vue')
      },
      {
        path: '/app/multisurveys',
        name: 'app-multisurveys',
        alias: '/multisurveys',
        component: () => import('@/pages/Surveys/MultiList.vue')
      },
      {
        path: '/app/surveys/media-mvp',
        name: 'app-surveys-media-mvp',
        alias: '/surveys-media-mvp',
        component: () => import ('@/pages/Surveys/MVPMedia.vue')
      },
      {
        path: '/app/surveys/:uuid',
        name: 'app-survey',
        alias: '/survey',
        component: () => import('@/pages/Surveys/One.vue')
      },
      {
        path: '/app/questions/universals',
        name: 'app-questions-universals',
        alias: '/questions-universals',
        component: () => import ('@/pages/Questions/Universals.vue')
      },
      {
        path: '/app/question/:uuid',
        name: 'app-question',
        alias: '/question',
        component: () => import('@/pages/Questions/One.vue')
      },
      {
        path: '/app/questions/categories',
        name: 'app-questions-categories',
        alias: '/questions-categories',
        component: () => import('@/pages/Questions/Categories/List.vue')
      },
      {
        path: '/app/questions/categories/:uuid',
        name: 'app-questions-category',
        alias: '/questions-category',
        component: () => import('@/pages/Questions/Categories/One.vue')
      },
      {
        path: '/app/questions/types',
        name: 'app-questions-types',
        alias: '/questions-categories',
        component: () => import('@/pages/Questions/Types/List.vue')
      },
      {
        path: '/app/articles',
        name: 'app-articles',
        alias: '/articles',
        component: () => import('@/pages/News/List.vue')
      },
      {
        path: '/app/articles/universals',
        name: 'app-articles-universals',
        alias: '/articles-universals',
        component: () => import ('@/pages/News/Universals.vue')
      },
      {
        path: '/app/articles/media-mvp',
        name: 'app-articles-media-mvp',
        alias: '/articles-media-mvp',
        component: () => import ('@/pages/News/MVPMedia.vue')
      },
      {
        path: '/app/articles/:uuid',
        name: 'app-article',
        alias: '/article',
        component: () => import('@/pages/News/List.vue')
      },
      {
        path: '/app/users/:role',
        name: 'app-users',
        alias: '/users',
        component: () => import('@/pages/Users/List.vue')
      },
      {
        path: '/app/user/:uuid',
        name: 'app-user',
        alias: '/user',
        component: () => import('@/pages/Users/One.vue')
      },
      {
        path: '/app/chats',
        name: 'app-chats',
        alias: '/chats',
        component: () => import('@/pages/Chats/List.vue')
      },
      {
        path: '/app/chats/:uuid',
        name: 'app-chats-chat',
        alias: '/chats-chat',
        component: () => import('@/pages/Chats/Chat.vue')
      },
      {
        path: '/app/requests/archives',
        name: 'app-requests-archives',
        alias: '/request-archives',
        component: () => import('@/pages/Requests/Archives/List.vue')
      },
      {
        path: '/app/requests/:uuid',
        name: 'app-requests-chat',
        alias: '/request-chat',
        component: () => import('@/pages/Requests/Chat.vue')
      },
      {
        path: '/app/parameters',
        name: 'app-parameters',
        alias: '/app-parameters',
        component: () => import('@/pages/Parameters/Home.vue')
      },
      // {
      //   path: '/app/documents',
      //   name: 'app-documents',
      //   alias: '/app-documents',
      //   component: () => import('@/pages/Documents/List.vue')
      // },
      {
        path: '/app/docs',
        name: 'app-events',
        alias: '/app-events',
        component: () => import('@/pages/Events/List.vue')
      },
      {
        path: '/app/groups',
        name: 'app-groups',
        alias: '/app-groups',
        component: () => import('@/pages/Groups/List.vue')
      },
      {
        path: '/app/groups/:uuid',
        name: 'app-group',
        alias: '/group',
        component: () => import('@/pages/Groups/Users.vue')
      },
      {
        path: '/app/groups/:uuid/users',
        name: 'app-group-users',
        alias: '/group-users',
        component: () => import('@/pages/Groups/Users.vue')
      },
      {
        path: '/app/versions',
        name: 'app-versions',
        alias: '/app-versions',
        component: () => import('@/pages/Versions/List.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
